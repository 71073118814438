import { URL } from ".";

const getRoutes = async () => {
  try {
    const response = await fetch(`${URL}/routes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}
export { getRoutes }