import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { CheckIcon, HandThumbUpIcon, UserIcon, ExclamationTriangleIcon, ExclamationCircleIcon, StopCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { getRoutes } from './api/routes'
import moment from 'moment-timezone'
import Loading from './Loading'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const generateRouteStatus = (route) => {
  // check alerts
  const alert_checkpoint = route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'digital_sign')[0];
  console.log({ alert_checkpoint })
  const alert = alert_checkpoint.latest_status.description;
  if (alert == 'no alerts') {
    // check duration
    return generateStatusFromDuration(route.duration);
  }
  if (alert.split(' ').includes('closed') || alert.split(' ').includes('CLOSED')) return 'bad';
  return 'mid';
}

const generateStatusFromDuration = (duration) => {
  if (duration.current <= duration.normal) return 'good';
  const percent = 1 - (Number(duration.normal) / Number(duration.current));
  if (percent < Number(0.25)) return 'good';
  if (percent < Number(0.5)) return 'mid';
  return 'bad';
}

const generateIcon = (status) => {
  switch (status) {
    case 'good':
      return <CheckIcon className="bg-green-500 rounded-full h-4 w-4 text-white" aria-hidden="true" />
    case 'mid':
      return <ExclamationTriangleIcon className="bg-yellow-500 rounded-full h-4 w-4 text-white" aria-hidden="true" />
    case 'bad':
      return <StopCircleIcon className="bg-red-500 h-4 w-4 text-white rounded-full" aria-hidden="true" />
    default:
      return <CheckIcon className="bg-green-500 h-4 w-4 text-white rounded-full" aria-hidden="true" />
  }
}


function App() {
  const [routes, setRoutes] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [tabs, setTabs] = useState(null)
  const [loading, setLoading] = useState(null);
  const fetchRoutes = async () => {
    setLoading(true);
    const response = await getRoutes();
    if (!response?.data) return
    setRoutes([...response?.data]);
    setSelectedRoute({ ...response?.data[0] });
    setTabs([...response?.data.map((route) => { return { name: route.name, id: route._id } })]);
    setLoading(false);
  }
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    fetchRoutes();
  }, [])

  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <p className="text-center mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Utah Ski Roads
          </p>

          <div className="mx-auto max-w-2xl text-center">
            {(loading) && <Loading className="" />}
            {(!loading) && <>
              {routes?.map((route, i) => {
                return <Route route={route} route_idx={i} />
              })}
            </>}

            {/* <p className="mt-6 text-lg leading-8 text-gray-600">
              Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
              pulvinar et feugiat blandit at. In mi viverra elit nunc.
            </p> */}
          </div>
          <div className="mx-auto mt-8 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            {/* <div className="justify-center flex">
              {selectedRoute && <div>
                <div className='mb-2'>
                  <h1>{selectedRoute?.name}</h1>
                  <p className='text-xs'>Updated: {moment(selectedRoute?.checkpoints[0]?.latest_status?.date_created).format('MM/DD/YY hh:mm:ss A') || ''}</p>
                </div>
                <ul role="list" className="">
                  {selectedRoute.checkpoints.sort((a, b) => a.order - b.order).map((checkpoint, i) => (
                    <li key={i}>
                      <Checkpoint
                        checkpoint={checkpoint}
                        checkpoint_length={selectedRoute?.checkpoints.length}
                        item_idx={i}
                      />
                    </li>
                  ))}
                </ul>
              </div>}
            </div> */}
          </div>
          {/* <div className='flex justify-center'>
            <span className="isolate inline-flex rounded-md shadow-sm">
              {tabs?.map((tab, i) => {
                const isActive = selectedRoute?._id === tab.id;
                return (
                  <button
                    type="button"
                    className={
                      classNames(
                        isActive ? 'bg-black text-white' : 'text-black bg-white  hover:text-gray-700',
                        `relative inline-flex items-center   px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${i === 0 ? 'rounded-l-md' : ''} ${i === tabs.length - 1 ? 'rounded-r-md' : ''}`
                      )
                    }
                    onClick={() => setSelectedRoute(tab.id)}
                  >
                    {tab.name.split(' ').map(word => word[0]).join('')}
                  </button>
                )
              })}
            </span>
          </div> */}
        </div >
      </div >
    </>
  );
}

function Route({ route, route_idx }) {
  const [expand, setExpand] = useState(false)
  return (
    <div className='flex flex-col border rounded-md border-grey200 py-8 my-4'>
      <div className='flex  px-6 space-x-2 items-center justify-between '>
        <div className='flex space-x-2'>
          <div className='pt-1'>{generateIcon(generateRouteStatus(route))}</div>
          <div>
            {route.name.split(' ').map(word => word[0]).join('')}
          </div>
        </div>
        {
          expand
            ? <ChevronUpIcon onClick={() => setExpand(!expand)} className="pt-1  h-6 w-6 text-xl font-bold rounded-full" aria-hidden="true" />
            : <ChevronDownIcon onClick={() => setExpand(!expand)} className="pt-1  h-6 w-6 text-xl font-bold rounded-full" aria-hidden="true" />
        }
      </div>
      {expand &&
        <div className={classNames(expand ? 'block' : 'hidden', 'pt-4')}>
          <RouteContent route={route} route_idx={route_idx} />
        </div>
      }
    </div>
  )
}


function RouteContent({ route, route_idx }) {
  const [showAlerts, setShowAlerts] = useState(false);
  const [showRoadCams, setShowRoadCams] = useState(false);
  const [showLotCams, setShowLotCams] = useState(false);
  const [showMtnCams, setShowMtnCams] = useState(false);

  return (
    <div className="mx-12 text-left">
      <p className='text-xs'>EST. TIME UP CANYON: {formatDuration(route?.duration?.current)}</p>
      <p className='text-xs'>UPDATED: {moment(route?.checkpoints[0]?.latest_status?.date_created).format('MM/DD hh:mm A') || ''}</p>
      <div className='flex flex-col'>
        <div className='flex' onClick={() => setShowAlerts(!showAlerts)}>
          <p className='text-xs'>ALERTS  </p>
          {showAlerts
            ? <ChevronUpIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
            : <ChevronDownIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
          }
        </div>
        <div className={classNames(showAlerts ? 'block' : 'hidden')}>
          {route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'digital_sign').map((checkpoint, i) => {
            return (
              <div className={classNames(i == 0 ? 'mt-4' : '', 'mb-2')} key={i}>
                <p className='text-xs relative z-10 font-bold'>{checkpoint?.name}</p>
                <p className='' >{checkpoint.latest_status.description}</p>
              </div>
            )
          }
          )}
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='flex' onClick={() => setShowRoadCams(!showRoadCams)}>
          <p className='text-xs'>ROAD CAMS  </p>
          {showAlerts
            ? <ChevronUpIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
            : <ChevronDownIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
          }
        </div>
        <div className={classNames(showRoadCams ? 'block' : 'hidden')}>
          {route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'road').map((checkpoint, i) => {
            return (
              <div className={classNames(i == 0 ? 'mt-4' : '', 'mb-2')} key={i}>
                <p className='text-xs relative z-10 font-bold'>{checkpoint?.name}</p>
                <img className='-mt-3 z-0' src={checkpoint.media_url} />
              </div>
            )
          })}
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='flex' onClick={() => setShowLotCams(!showLotCams)}>
          <p className='text-xs'>LOT CAMS  </p>
          {showAlerts
            ? <ChevronUpIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
            : <ChevronDownIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
          }
        </div>
        <div className={classNames(showLotCams ? 'block' : 'hidden')}>
          {route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'parking_lot').map((checkpoint, i) => {
            return (
              <div className={classNames(i == 0 ? 'mt-4' : '', 'mb-2')} key={i}>
                <p className='text-xs relative z-10 font-bold'>{checkpoint?.name}</p>
                <img className='-mt-3 z-0' src={checkpoint.media_url} />
              </div>
            )
          })}
        </div>
      </div>

      <div className='flex flex-col'>
        <div className='flex' onClick={() => setShowMtnCams(!showMtnCams)}>
          <p className='text-xs'>MTN CAMS  </p>
          {showAlerts
            ? <ChevronUpIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
            : <ChevronDownIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
          }
        </div>
        <div className={classNames(showMtnCams ? 'block' : 'hidden')}>
          {route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'mountain').map((checkpoint, i) => {
            return (
              <div className={classNames(i == 0 ? 'mt-4' : '', 'mb-2')} key={i}>
                <p className='text-xs relative z-10 font-bold'>{checkpoint?.name}</p>
                <img className='-mt-3 z-0' src={checkpoint.media_url} />
              </div>
            )
          })}

        </div>
      </div>

    </div>
  )
}

function formatDuration(seconds) {
  const hours = Math.floor(seconds / 3600); // 3600 seconds in 1 hour
  const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60

  if (hours == '0') return `${minutes}m`;
  return `${hours}h ${minutes}m`;
}

function Checkpoint({ checkpoint, checkpoint_length, item_idx }) {
  const { _id, latest_status, name, media_url } = checkpoint;
  const [expand, setExpand] = useState(false);
  return (
    <div className="relative pb-8">
      {item_idx !== checkpoint_length - 1 ? (
        <span className="absolute left-2 top-2 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
      ) : null}
      <div className="relative flex space-x-3">
        <div>
          {generateIcon(latest_status?.status)}
        </div>
        <div className="flex flex-col min-w-0 flex-1 justify-between space-x-4 ">
          <div onClick={() => setExpand(!expand)}>
            <p className="text-sm text-gray-500">
              {name}
            </p>
          </div>
          <div className={
            classNames(expand ? 'flex max-w-50' : 'hidden', 'flex-col flex-shrink-0 self-center',
              '')
          }>
            <img src={media_url} className='max-h-48 w-48' />
            <a href={media_url} className="text-sm text-gray-500">
              link
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}



export default App;

